@tailwind base;
@tailwind components;
@tailwind utilities;

 body {
  background-color: #000300
 }

 /* src/index.css or other global CSS file */
@keyframes moveUpDown {
    0%, 100% {
      transform: translateY(var(--move-start, 0));
    }
    50% {
      transform: translateY(var(--move-end, 50%)); /* Adjust the movement distance as needed */
    }
  }

  @keyframes bobbing {
    0%, 100% {
        transform: translateY(0);  /* Start and end at the original position */
    }
    50% {
        transform: translateY(-5px); /* Move up 5 pixels */
    }
}
.bobbing-animation {
    animation: bobbing 1s ease-in-out infinite; /* Adjust timing as needed */
}

  .move-up-down {
    animation: moveUpDown 20s ease-in-out infinite; /* Adjust timing as needed */
  }
  
  @keyframes blink-size {
    0%, 100% { transform: scale(1); }  /* Start and end at normal size */
    50% { transform: scale(0.95); }     /* Scale up to 120% at the midpoint */
}

.blink-size {
    animation: blink-size 1s infinite; /* Run animation continuously every second */
}
